html, body {
    background-color: #181a1b;
    color: rgba(255, 255, 255, .87);
    font-family: Roboto, sans-serif;

    overflow: hidden;

    width: 100%;
    height: 100%;
    margin: 0;

    transition: background-color .5s;
}

* {
    font-family: Roboto, sans-serif;
}

a {
    color: rgba(150, 150, 255, .87);
}

a:visited {
    color: rgba(150, 150, 255, .77);
}

#mass, #elements, #formula {
    position: absolute;

    text-align: center;
    text-shadow: 0px 0px 10px black;
    color: rgba(255, 255, 255, .8);

    overflow: auto;
}

#mass {
    overflow: visible;

    bottom: 2px;
    left: 25%;

    width: 50%;

    color: rgba(255, 255, 255, .8);

    font-size: 48px;
}

#popup {
    display: none;

    position: absolute;
    top: 25%;
    left: 10px;

    z-index: 99;

    transform: translateY(-50%);

    width: 300px;

    text-align: center;

    font-size: 24px;

    padding: 5px;

    overflow: auto;

    border-radius: 2px;
    border: 3px solid rgba(80, 82, 84, 0.75);

    background-color: rgba(61, 65, 68, 0.5);

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
}

#popup.show {
    display: block;
}

#popup-title {
    font-weight: bold;
}

#mass-popup {
    display: none;
}

#mass-popup.show {
    display: block;
}

img[src=""] {
    display: none;
}

#mass:hover #mass-popup,
#mass:active #mass-popup {
    display: block;
}

#mass-popup {
    max-height: 15vh;

    overflow-y: auto;

    max-width: 400px;
    width: 80%;
    padding: 5px;

    font-size: 24px;

    margin: auto;

    border-radius: 2px;
    border: 3px solid rgba(80, 82, 84, 0.75);

    background-color: rgba(61, 65, 68, 0.5);

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);

    margin-bottom: 5px;

    z-index: 10;
}

.row {
    display: block;

    clear: both;
    float: none;

    height: 30px;
}

.row-left {
    float: left;
}

.row-right {
    float: right;

    font-weight: bold;
}

details > span {
    overflow-x: auto;
    white-space: nowrap;
}

#model-image {
    position: absolute;
    top: 27.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.9;
    z-index: 0;
    filter: invert(93%);
    pointer-events: none;
}

#model-label {
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.7;
    z-index: 0;
}

#elements {
    top: 57.5%; /*calc(40% + 120px); /* 60%; */
    left: 12.5%;

    width: 75%;
    height: 80px;

    font-size: 24px;

    white-space: nowrap;

    overflow-x: auto;
    overflow-y: visible;
}

#elements-body > span {
    cursor: pointer;
}

#formula {
    top: 42.5%;
    left: 12.5%;

    height: 15%;
    width: 75%;

    color: rgba(255, 255, 255, .99);
    font-size: 56px;

    background-color: transparent;

    border: none;
    border-radius: 5px;
}

#formula:focus {
    outline: none;
    /*border: 2px solid rgba(255, 255, 255, .3);

    background-color: rgba(255, 255, 255, .1);*/
}

#snackbar-update, #snackbar-install {
    display: none;
}

#snackbar-update.show, #snackbar-install.show {
    display: inline;
}

#snackbar {
    position: absolute;
    /* display: none; */

    top: -50px;

    height: 35px;

    width: 50%;
    left: 25%;

    background-color: rgb(60, 64, 66);

    border-radius: 2px;
    border: 3px solid rgb(93, 93, 93);

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);

    transition: all 1s;

    z-index: 100;
}

#snackbar *:not(div) {
    margin-top: 6px;
}

#snackbar button {
    float: right;
    margin-right: 10px;
}

#snackbar p {
    display: inline-block;
    margin-left: 10px;
}

#snackbar.show {
    top: 8px;
}

#options {
    position: absolute;

    left: 0px;

    width: calc(100%);
    top: calc(-20px - 150px - 6px);

    transition: all 1s;

    z-index: 2;

    pointer-events: none;
}

#options-panel {
    margin: auto;
    width: 500px;

    height: 150px;

    background-color: rgba(61, 65, 68, 0.5);

    border-radius: 2px;
    border: 3px solid rgba(80, 82, 84, 0.75);

    padding: 10px;

    text-align: center;

    pointer-events: all;

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);

    z-index: 9;
}

#options.show {
    top: 10px;
}

#options-drawer {
    cursor: pointer;

    margin: auto;

    width: 20.5px;
    height: 20px;

    padding-left: 6px;
    padding-bottom: 6px;

    font-size: 18px;
    line-height: 25px;

    background-color: rgba(100, 102, 104, .6);

    border: 3px solid rgba(100, 102, 104, .75);
    border-top: none;
    border-radius: 0px 0px 2px 2px;

    color: rgba(255, 255, 255, .8);

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);

    pointer-events: all;

    opacity: 0.3;
}

#options-drawer:hover {
    opacity: 0.5;
}

#info {
    margin-bottom: 20px;
}

#info > *:not(:first-child) {
    display: block;
}

#other-switches {
    margin-top: 5px;
    margin-bottom: 20px;
}

.switches-dual-column {
    margin-top: 5px;
    width: 100%;
}

.switches-dual-column > * {
    width: 45%;
    display: inline-block;
}

#compounds, #equations {
    position: absolute;
    top: 50%;

    transform: translateY(-50%);

    transition: all 1s;

    z-index: 1;

    pointer-events: none;
}

#equations-drawer, #compounds-drawer {
    cursor: pointer;

    top: 50%;

    transform: translateY(-50%);

    position: absolute;

    width: 20px;
    height: 20.5px;

    padding-left: 4px;
    padding-bottom: 6px;

    font-size: 32px;
    line-height: 25px;

    background-color: rgba(100, 102, 104, .6);

    border: 3px solid rgba(100, 102, 104, .75);

    color: rgba(255, 255, 255, .8);

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);

    pointer-events: all;

    opacity: 0.3;
}

#equations-drawer:hover, #compounds-drawer:hover {
    opacity: 0.5;
}

#equation-search, #compound-search {
    width: calc(100% - 50px - 10px);

    color: rgba(255, 255, 255, .99);
    background-color: rgba(100, 102, 104, .2);

    height: 5%;

    border: none;

    margin: 0;
    padding: 5px;

    text-align: center;

    display: inline-block;
}

#equation-sort, #compound-sort {
    width: 50px;

    padding: 2.5px;

    font-size: 13px;

    height: calc(7% + 2px);

    border: none;

    float: right;

    background-color: rgba(100, 102, 104, .4);
    color: rgba(255, 255, 255, .99);
}

option {
    background-color: rgb(60, 60, 60);
    color: rgb(255, 255, 255);
}

#equation-list, #compound-list {
    overflow-y: scroll;
    overflow-x: hidden;

    height: 92.5%;
}

#equation-list > button, #compound-list > button {
    overflow: hidden;

    width: 100%;
    height: 30px;
    border-radius: 0;

    padding: 5px;
}

.list-results {
    cursor: default;

    background-color: rgb(60, 64, 66);
}

#equations {
    left: calc(-6px - 200px);
}

#equations.show {
    left: 10px;
}

#equations-drawer {
    right: -27px;
    border-left: none;
    border-radius: 0px 2px 2px 0px;
}

#compounds {
    position: absolute;

    top: 50%;
    right: calc(-6px - 200px);

    transform: translateY(-50%);

    transition: all 1s;

    z-index: 1;

    pointer-events: none;
}

.panel {
    height: 400px;

    width: 200px;

    background-color: rgba(61, 65, 68, 0.5);

    border-radius: 2px;
    border: 3px solid rgba(80, 82, 84, 0.75);

    pointer-events: all;

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);

    z-index: 9;
}

#compounds.show {
    right: 10px;
}

#compounds-drawer {
    left: -27px;
    border-radius: 2px 0px 0px 2px;
    border-right: none;
}


#highlight {
    position: absolute;
    height: calc(15% - 25px);
    opacity: 0.5;
    background-color: yellow;

    display: none;
}

.mass-overlay {
    position: absolute;
    font-size: 32px;
    opacity: 0.8;

    text-align: center;
}

#testing {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;

    background-color: rgba(0, 0, 0, .8);

    backdrop-filter: saturate(180%) blur(5px);

    z-index: 999;

    height: 0;
    opacity: 0;

    transition: all 1s;
}

summary:focus {
    outline: none !important;
}

summary {
    cursor: pointer;
}

/*details[open] summary h2 {
    margin-bottom: 10px;
}

summary h4::after {
    position: absolute;
    right: 20px;

    content: '+';
}

details[open] summary h4::after {
    content: '-';
}

summary::-webkit-details-marker {
    display: none
}*/

summary::-webkit-details-marker {
    margin-bottom: 2.5px;
}

#testing-content h2 {
    margin-top: 0;
    margin-bottom: 0;

    display: inline-block;

    width: calc(100% - 20px);
}

#testing-content h2 > h4 {
    float: right;

    font-weight: normal;
    font-size: 16px;

    margin-top: 5px;
    margin-right: 40px;

    display: block;

    color: rgba(255, 255, 255, .87);
}

#testing-content h4 {
    font-weight: normal;

    margin-top: 0;
    margin-bottom: 5px;

    margin-left: 40px;
}

#testing-content > details {
    margin-bottom: 15px;
}

/*#testing-content > details > div {
    max-height: 200px;
    overflow: auto;
}*/

details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
}
  
@keyframes sweep {
    0%    {opacity: 0; margin-left: -40px}
    100%  {opacity: 1; margin-left: 0px}
}

#testing.show {
    height: 100%;
    opacity: 1;
}

#testing-header {
    float: left;

    margin-top: 15px;
    margin-left: 15px;

    margin-bottom: 15px;
}

#testing-right {
    float: right;
    text-align: right;

    margin-top: 15px;
    margin-right: 15px;
    
    margin-bottom: 0;
}

#testing-content {
    margin-left: 40px;

    width: calc(100% - 40px);

    height: calc(100% - 90px);

    overflow: auto;
}

#testing-loading.show {
    opacity: .8;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#testing-loading {
    opacity: 0;

    width: 75%;

    transition: opacity .5s;

    position: absolute;

    top: -999px;

    left: 50%;
    transform: translateX(-50%);
}

#testing-loading-label {
    text-align: center;
    width: 100%;

    display: block;
    font-size: 36px;
}

#testing-loading-bar {
    width: 100%;
    height: 20px;

    border-radius: 1px;
}

#testing-loading-bar::-webkit-progress-bar {
    background-color: rgba(50, 50, 50, .8);
}

#testing-loading-bar::-webkit-progress-value {
    background-color: rgba(150, 150, 150, .8);
}

*:not(h2).test-pass::before, *:not(h2).test-fail::before {
    content: attr(before-text);
    margin-right: 5px;
}

.test-pass::before {
    color: #87CA00;
}

.test-fail::before {
    color: #FF0000;
}

h2.test-pass {
    color: #CFFF70;
}

h2.test-fail {
    color: #FF7070;
}

*:not(h2).test-fail, h1.test-fail {
    color: #D90000;
}

h1.test-pass {
    color: #689C00;
}

#quiz {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: none;
}

#quiz-title {
    position: absolute;
    top: 20%;

    transform: translateY(-50%);

    width: 100%;

    text-align: center;
    font-size: 42px;
}

#quiz-options {
    margin-top: 55vh;

    transform: translateY(-50%);
}

.quiz-option {
    font-size: 38px;

    margin: auto;

    width: 50%;
    min-width: 250px;
    height: 60px;

    margin-bottom: 50px;
}

body[quiz=true] * {
    display: none;
}

body[quiz=true] > #quiz {
    display: block !important;
}

body[quiz=true] > #quiz * {
    display: block !important;
}


button {
    background-color: rgb(80, 84, 86);
    color: rgba(255, 255, 255, .87);

    border: none;
    border-radius: 2px;

    height: 24px;

    cursor: pointer;

    transition: all 0.5s;
}

button:hover {
    background-color: #6f6f6f;
    color: rgba(255, 255, 255, 1);
}

.switch {
    position: relative;
    display: inline-block;

    width: 30px;
    height: 17px;

    top: 2px;

    margin-right: 3px;
    margin-bottom: 5px;
}

.switch input {
    opacity: 0;

    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(120, 122, 120, .7);

    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;

    content: "";

    height: 13px;
    width: 13px;

    left: 2px;
    bottom: 2px;

    background-color: rgba(40, 42, 44, .8);

    -webkit-transition: .4s;
    transition: .4s;
}

#changelog {
    top: -60%;

    position: absolute;
    left: 50%;

    transform: translate(-50%, -50%);

    width: 90%;
    height: 90%;

    padding: 10px;

    background-color: rgba(61, 65, 68, 0.5);
    border: 3px solid rgba(80, 82, 84, 0.75);
    border-radius: 2px;

    box-shadow: 0px 0px 5px rgba(0, 0, 0, .5);

    z-index: 999;

    overflow: auto;

    transition: all 1s;
}

#changelog.show {
    top: 50%;
}

#changelogBackground {
    position: absolute;

    top: -100%;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;

    z-index: 998;

    background-color: rgb(0, 0, 0);

    transition: opacity 3s;
}

#changelog.show + #changelogBackground {
    top: 0;

    opacity: 0.5;
}

#changelog h1 {
    margin-top: 0px;
}

#changelog h2 {
    border-bottom: 1px solid #ccc;
    font-size: 32px;
}

#changelog h3 {
    font-size: 24px;
    margin-bottom: 0px;
}

#changelog h4 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-left: 10px;
}

#changelog ul {
    margin-left: 10px;
}

input:checked + .slider {
    background-color: rgba(180, 182, 184, .9);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

::-webkit-scrollbar {
    background-color: #1c1e1f;
    color: #c5c1b9;
}

::-webkit-scrollbar-thumb {
    background-color: #2a2c2e;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #323537;
}

::-webkit-scrollbar-thumb:active {
    background-color: #3d4043;
}

::-webkit-scrollbar-corner {
    background-color: #181a1b;
}

* {
    scrollbar-color: #2a2c2e #1c1e1f;
}

@media (max-width: 900px) {
    #snackbar {
        width: 75%;
        left: 12.5%;
    }

    #mass-popup {
        width: 95%;
    }
}

@media (max-width: 600px) {
    #snackbar {
        width: 95%;
        left: 2.5%;
    }

    #options {
        top: calc(-20px - 195px - 6px);
    }

    #options-panel {
        width: 80%;
        height: 195px;
    }

    .switches-dual-column > * {
        width: 100%;
    }

    #mass-popup {
        margin-left: -15%;
        width: 125%;
    }
}

/*
#visualisation {
    position: absolute;

    width: 90%;
    height: 90%;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    z-index: -10;
}

 #nucleus {
    position: absolute;

    width: 150px;
    height: 150px;

    top: 50%;
    left: 50%;

    margin: -75px 0 0 -75px;

    background-color: yellow;
    border-radius: 50%;
}

.ring {
    position: absolute;

    width: 300px;
    height: 300px;

    top: 50%;
    left: 50%;

    margin: -160px 0 0 -160px;

    border-radius: 50%;
    border: 10px solid rgba(10, 12, 255, .9);

    z-index: -99;

    padding: 0;
}

#ring-2 {
    width: 400px;
    height: 400px;

    margin: -210px 0 0 -210px;
}

#ring-3 {
    width: 450px;
    height: 450px;

    margin: -235px 0 0 -235px;
}

.electron {
    list-style: none;

    width: 20px;
    height: 20px;

    position: absolute;

    background-color: red;

    border-radius: 50%;

    margin-top: -5px;

    top: 50%;
    left: 50%;
}

/*
.electron {
    position: relative;

    top: -15px;
    left: calc(50% - 10px);

    width: 20px;
    height: 20px;

    border-radius: 50%;

    background-color: red;
}

#electron-2 {
    top: calc(5% - 22.5px);
    left: calc(75% - 0px);
}

#electron-3 {
    top: calc(90% - 22.5px);
    left: calc(75% - 0px);
}

#electron-4 {
    top: calc(75% - 5px);
    left: calc(75% - 0px);
}

#electron-5 {
    top: calc(100% - 25px);
    left: calc(50% - 10px);
}
*/
